import React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import img from "../assets/images/Frame 62 (1).png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "../assets/styles/about.css";

const About = () => {
  return (
    <div className="section-container" id="about"    >
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <Container>
          <div className="section-title text-center">
            <h2>About</h2>
            {/* <p>Buy & Sell Business</p> */}
          </div>
          <Row>
            <Col
              md={12}
              lg={6}
              className="about-section-text order-sm-1 order-md-0"
            >
              <Stack>
                <div className="txt mb-3">
                Welcome to Buy and Sell Business, your premier platform for connecting investors with small and medium-sized business opportunities. Founded with the mission to simplify and streamline the process of buying and selling businesses, we are dedicated to helping entrepreneurs achieve their business goals through seamless and efficient transactions.
                </div>
                <div className="txt mb-3">
               
Our mission is to create a robust and reliable marketplace where business owners and investors can connect, negotiate, and complete transactions with confidence. We strive to facilitate growth, provide expert guidance, and ensure that every transaction is secure and beneficial for all parties involved.
                </div>
                <div className="txt mb-3">
                Buy and Sell Business was born out of the recognition that small and medium-sized businesses often face significant challenges when looking to sell or find investment opportunities. Our founders, experienced entrepreneurs themselves, understood the need for a platform that could bridge the gap between buyers and sellers, providing a comprehensive suite of services to support business transactions from start to finish                </div>
              </Stack>
            </Col>
            <Col md={12} lg={6} className="order-sm-0 order-md-1 mb-sm-3">
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                animateOnce={true}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={img}
                    alt=""
                    style={{ maxHeight: "500px", borderRadius: "2rem" }}
                  />
                </div>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </AnimationOnScroll>
    </div>
  );
};

export default About;
