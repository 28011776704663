import React from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="section-container footer">
      <Container>
        <div className="row">
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="copyright">
              &copy; Copyright <strong><span>Buy & Sell Business</span></strong>. All Rights Reserved
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="credits">
              <Link to="/terms"  target="_blank">Terms & Conditions</Link> | <Link to="/privacy"
               target="_blank"
              >Privacy Policy</Link> | <Link to="/user-policy"
               target="_blank"
              >Users Policy</Link> |   <Link to="/refund"  target="_blank">Refund & Cancellation</Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer